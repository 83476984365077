/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.MuiFormControl-root {
  margin-bottom: 12px;
  border-radius: 12px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-row {
  cursor: pointer !important;
}

/***************** form styles override /*****************/

.MuiFormControl-root {
  margin-bottom: 12px !important;
}
.MuiInputBase-root {
  border-radius: 10px;
}
.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #0a3a67 !important;
  opacity: 0.8 !important;
}
.MuiFormLabel-root {
  border-radius: 5px !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  padding-bottom: 10px !important;
  margin-top: 4px !important;
  color: #0a3a67 !important;
}
.MuiOutlinedInput-root {
  border-radius: 12px;
}
.MuiOutlinedInput-input {
  font-size: '16px' !important;
  font-family: 'Lato', sans-serif !important;
  color: #0a3a67 !important;
  font-weight: 500 !important;
  border-radius: 12px !important;
}
.MuiFormHelperText-root {
  color: '#bf3333' !important;
}
.MuiMenuItem-root {
  color: '#0A3A67' !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.MuiTypography-root {
  font-family: 'Lato', sans-serif !important;
}
.MuiChip-root {
  margin-right: 8px !important;
  background-color: #fff !important;
  border: none !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 12px !important;
  color: #0a3a67 !important;
  font-weight: 500 !important;
}
.MuiClock-root {
  margin: 0 !important;
}
.MuiClockPicker-root {
  width: 100% !important;
  max-height: 100% !important;
}
.MuiClock-clock {
  background-color: #fff !important;
}
.react-calendar__navigation {
  height: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}
